.text-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    color: #fff;
}

.sophisticated {
    font-family: 'Playfair Display', serif;
    font-size: 48px;
}